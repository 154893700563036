<template>
    <div>
        加载中...
    </div>
</template>
<script>
export default {
    name:'face',
    computed:{
        location(){
            return  window.location
        }
    },
    created(){
        this.back()
    },
    methods: {
        back(){
            const {passed,result} = this.$route.query
            wx.miniProgram.redirectTo({ 
                url:`/activity/face-result/index?passed=${passed}&result=${result}`
            })
        }
    },
}
</script>